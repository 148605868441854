// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-history-jsx": () => import("./../../../src/pages/about/history.jsx" /* webpackChunkName: "component---src-pages-about-history-jsx" */),
  "component---src-pages-about-mission-jsx": () => import("./../../../src/pages/about/mission.jsx" /* webpackChunkName: "component---src-pages-about-mission-jsx" */),
  "component---src-pages-about-patron-jsx": () => import("./../../../src/pages/about/patron.jsx" /* webpackChunkName: "component---src-pages-about-patron-jsx" */),
  "component---src-pages-about-personnel-jsx": () => import("./../../../src/pages/about/personnel.jsx" /* webpackChunkName: "component---src-pages-about-personnel-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-everyday-additional-lessons-jsx": () => import("./../../../src/pages/everyday/additional-lessons.jsx" /* webpackChunkName: "component---src-pages-everyday-additional-lessons-jsx" */),
  "component---src-pages-everyday-calendar-jsx": () => import("./../../../src/pages/everyday/calendar.jsx" /* webpackChunkName: "component---src-pages-everyday-calendar-jsx" */),
  "component---src-pages-everyday-groups-jsx": () => import("./../../../src/pages/everyday/groups.jsx" /* webpackChunkName: "component---src-pages-everyday-groups-jsx" */),
  "component---src-pages-everyday-plan-jsx": () => import("./../../../src/pages/everyday/plan.jsx" /* webpackChunkName: "component---src-pages-everyday-plan-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-parents-adaptation-jsx": () => import("./../../../src/pages/parents/adaptation.jsx" /* webpackChunkName: "component---src-pages-parents-adaptation-jsx" */),
  "component---src-pages-parents-development-jsx": () => import("./../../../src/pages/parents/development.jsx" /* webpackChunkName: "component---src-pages-parents-development-jsx" */),
  "component---src-pages-parents-documents-jsx": () => import("./../../../src/pages/parents/documents.jsx" /* webpackChunkName: "component---src-pages-parents-documents-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/blog-page.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-gallery-page-jsx": () => import("./../../../src/templates/gallery-page.jsx" /* webpackChunkName: "component---src-templates-gallery-page-jsx" */)
}

